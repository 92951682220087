import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const List = makeShortcode("List");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "curated-material---patterns-and-trust",
      "style": {
        "position": "relative"
      }
    }}>{`Curated Material - Patterns and Trust`}<a parentName="h1" {...{
        "href": "#curated-material---patterns-and-trust",
        "aria-label": "curated material   patterns and trust permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <List mdxType="List">
      <Link to="https://www.humanetech.com/podcast/28-two-million-years-in-two-hours-a-conversation-with-yuval-noah-harari" mdxType="Link">
        <h4 {...{
          "id": "two-millon-years-in-two-hours-a-conversation-with-yuval-noah-harari",
          "style": {
            "position": "relative"
          }
        }}>{`Two Millon Years in Two Hours: A Conversation With Yuval Noah Harari`}<a parentName="h4" {...{
            "href": "#two-millon-years-in-two-hours-a-conversation-with-yuval-noah-harari",
            "aria-label": "two millon years in two hours a conversation with yuval noah harari permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h4>
        <p>{`An illuminating podcast by the Center of Humane Technology, a discussion between Tristan Harris and Yuval Noah Harari that will give us not just a broader look into the patterns of human history, but also a deeper understanding of the attention economy.`}</p>
      </Link>
      <Link to="https://www.deconstructoroffun.com/" mdxType="Link">
        <h4 {...{
          "id": "deconstructor-of-fun",
          "style": {
            "position": "relative"
          }
        }}>{`Deconstructor of Fun`}<a parentName="h4" {...{
            "href": "#deconstructor-of-fun",
            "aria-label": "deconstructor of fun permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h4>
        <p>{`Breaking down what the most successful games are and what makes them tick. A good overview on how game designers create games to find success in the app stores.`}</p>
      </Link>
      <Link to="https://mitpress.mit.edu/books/virtual-economies" mdxType="Link">
        <h4 {...{
          "id": "virtual-economies-design-and-analysis",
          "style": {
            "position": "relative"
          }
        }}>{`Virtual Economies: Design and Analysis`}<a parentName="h4" {...{
            "href": "#virtual-economies-design-and-analysis",
            "aria-label": "virtual economies design and analysis permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h4>
        <p>{`A must-read for anyone creating games, as economies and user interactions are what form the basis of trust. More relevant than ever before, especially for Web 3 game developers.`}</p>
      </Link>
      <Link to="https://medium.com/curiouserinstitute/a-game-designers-analysis-of-qanon-580972548be5" mdxType="Link">
        <h4 {...{
          "id": "a-game-designers-analysis-of-qanon",
          "style": {
            "position": "relative"
          }
        }}>{`A Game Designer's Analysis of QAnon`}<a parentName="h4" {...{
            "href": "#a-game-designers-analysis-of-qanon",
            "aria-label": "a game designers analysis of qanon permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h4>
        <p>{`A game designer analyzes how playing with pattern recognition can lead to disjointed realities.`}</p>
      </Link>
      <Link to="https://www.ted.com/talks/emily_levine_a_theory_of_everything" mdxType="Link">
        <h4 {...{
          "id": "a-theory-of-everything",
          "style": {
            "position": "relative"
          }
        }}>{`A Theory of Everything`}<a parentName="h4" {...{
            "href": "#a-theory-of-everything",
            "aria-label": "a theory of everything permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h4>
        <p>{`A fun talk by philosopher-comic Emily Levine to help us think in complementary opposites. She enjoins us to think like tricksters as `}<em parentName="p">{`the trickster has the ability to hold his ideas lightly so that he can let room in for new ideas or to see the contradictions or the hidden problems with his ideas`}</em>{`. `}</p>
      </Link>
    </List>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      